// booking - children age
function showField(){
    var select = document.getElementById('children').selectedIndex;

    if(select == 0) {
	    $("#childage1").prop( "disabled", true ); //Disable  
	    $("#childage2").prop( "disabled", true ); //Disable  
	    $("#childage3").prop( "disabled", true ); //Disable  
    }
  
    if(select == 1) {
	    $("#childage1").prop( "disabled", false ); //Enable
	    $("#childage2").prop( "disabled", true ); //Disable  
	    $("#childage3").prop( "disabled", true ); //Disable
    }
        
    if(select == 2) {
	    $("#childage1").prop( "disabled", false ); //Enable
	    $("#childage2").prop( "disabled", false ); //Enable  
	    $("#childage3").prop( "disabled", true ); //Disable
    }
        
    if(select == 3){
	    $("#childage1").prop( "disabled", false ); //Enable
	    $("#childage2").prop( "disabled", false ); //Enable
	    $("#childage3").prop( "disabled", false ); //Enable
    }       
}


jQuery(document).ready(function($) {
	if ($("html").hasClass("lt-ie9")) {
		$(".columns div:first-child").css( "margin-left", "0" );
	}

	var vw = $(window).width();

	// headerOnScroll
	$(window).bind('scroll', function() {
		var st = $(window).scrollTop();

		if (st > 0 && vw > 900) {
			$('.headerOnScroll').addClass('fixed');
		} else {
			$('.headerOnScroll').removeClass('fixed');
		}
	});

	$(window).scroll(function () {
		var top_offset = $(window).scrollTop();
		if (top_offset == 0) {
			$('nav li').removeClass('current');
		}
	});

	// toggle menu
	$('.toggleMenu').on('click', function() {
		$('.navigation').slideToggle(300);
		return false;
	});

	// active menu point
	var loc = window.location.href;

	if(location.pathname == "/") {
        $('#index').addClass('active');
    }

	if(/index/.test(loc)) {
		$('#index').addClass('active');
	}

	if(/ferienwohnung/.test(loc)) {
		$('#sub-menu').addClass('active');
	}

	if(/fewo-magdalena/.test(loc)) {
		$('#sub-menu, #magdalena').addClass('active');
	}

	if(/niko-ferienwohnung/.test(loc)) {
		$('#sub-menu, #niko').addClass('active');
	}
	
	if(/angelina-fewo/.test(loc)) {
		$('#sub-menu, #angelina').addClass('active');
	}

	if(/umgebung/.test(loc)) {
		$('#surrounding').addClass('active');
	}

	if(/kontakt/.test(loc)) {
		$('#contact').addClass('active');
	}

	if(/buchung/.test(loc)) {
		$('#booking').addClass('active');
	}

	// sub-menu
	if(vw > 900) {
		$('.sub-menu').hover(function() {
			$('.sub-menu ul').slideToggle(300);
			return false;
		});
	}

	// bxslider
	$('.bxslider').bxSlider({
		mode: 'fade',
		auto: true,
		captions: true,
		pager: false,
		prevText: '',
		nextText: ''
	});

	$('.bxslider-room').bxSlider({
		mode: 'fade',
		captions: true,
		pagerCustom: '#bx-pager',
		prevText: '',
		nextText: ''
	});

	// magnific popup
	$('.gallery-1').magnificPopup ({
		items: [
			{
				src: 'assets/images/galleries/gallery-1/betten.jpg',
				title: 'Ferienwohnung Magdalena - Elternzimmer'
			},
			/*{
				src: 'assets/images/galleries/gallery-1/bodensee2.jpg' ,
				title: 'Promanade am Seeufer'
			},
			{
				src: 'assets/images/galleries/gallery-1/bodensee3.jpg' ,
				title: 'Weingut oberhalb des Bodensees'
			},
			{
				src: 'assets/images/galleries/gallery-1/bodensee4.jpg' ,
				title: 'Kleiner Hafen im Bodensee'
			}*/
		],
		gallery: {
			enabled: true
		},
		type: 'image'
	});

	$('.gallery-2').magnificPopup ({
		items: [
			{
				src: 'assets/images/galleries/gallery-2/eingang.jpg' ,
				title: 'Eingangsbereich'
			}
		],
		gallery: {
			enabled: true
		},
		type: 'image'
	});

	$( '.gallery-3' ).magnificPopup({
		items: [
			{ 	src: 'assets/images/galleries/gallery-3/essbereich.jpg' ,
				title: 'Essbereich der Ferienwohnung Magdalena'
			}
		],
		gallery: {
			enabled: true
		},
		type: 'image'
	});

	$( '.gallery-4' ).magnificPopup({
		items: [
			{
				src: 'assets/images/galleries/gallery-4/wohnraum.jpg',
				title: 'Wohnzimmer in der Ferienwohnung Magdalena'
			}
		],
		gallery: {
			enabled: true
		},
		type: 'image'
	});

	$( '.gallery-5' ).magnificPopup({
		items: [
			{
				src: 'assets/images/galleries/gallery-5/kueche.jpg',
				title: 'Hochwertige Küche in der Ferienwohnung Magdalena'
			}
		],
		gallery: {
			enabled: true
		},
		type: 'image'
	});

    $( '.gallery-6' ).magnificPopup({
        items: [
            {
                src: 'assets/images/galleries/gallery-6/bad.jpg',
                title: 'Das moderne Badezimmer der Ferienwohnung Angelina'
            }
        ],
        gallery: {
            enabled: true
        },
        type: 'image'
    });

	// booking-area
	if(vw < 1200) {
		$('.booking-area h3').on('click', function() {
			$('.booking-area-form').slideToggle(300);
			$(this).toggleClass('open');
		});
	}

	// datepicker
	$('#check-in').datepicker({
		onClose: function( selectedDate ) {
			$('#check-out').datepicker( 'option', 'minDate', selectedDate );
		}
	});
	$('#check-out').datepicker({
		onClose: function( selectedDate ) {
			$('#check-in').datepicker( 'option', 'maxDate', selectedDate );
		}
	});

	$.datepicker.setDefaults({
		dateFormat: 'dd.mm.yy',
		dayNamesMin: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],
		monthNames: ['Januar','Februar','März','April','Mai','Juni',
			'Juli','August','September','Oktober','November','Dezember'],
		monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun',
			'Jul','Aug','Sep','Okt','Nov','Dez'],
		firstDay: 1,
		numberOfMonths: 1
	});

	// tabs
	$( function() {
		$('#tabs').tabs();
	});

	//scroll top
	$('#go-to-top').css({'display':'none'});
	$('#go-to-top').click(function() {
		$('html, body').stop().animate({
			scrollTop: 0
		}, 1000);
	});

	//scroll top fade in & out
	$(window).scroll(function() {
		if ($(this).scrollTop() > 80) {
			$('#go-to-top').fadeIn();
		} else {
			$('#go-to-top').fadeOut();
		}
	});

	// form validatiom
	/*$('.booking-form').validate({
		rules: {
			name: 'required',
			email: {
				required: true,
				email: true
			},
			email_again: {
				equalTo: '#email'
			},
			checkin: 'required',
			checkout: 'required'
		},
		messages: {
			name: 'Bitte geben Sie Ihren Namen ein.',
			email: 'Keine gültige E-Mail Adresse.',
			email_again: 'Nicht identisch.',
			checkin: 'Bitte geben Sie ein Datum ein.',
			checkout: 'Bitte geben Sie ein Datum ein.'
		}
	});

    $('.contact-form').validate({
        rules: {
            name: 'required',
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            name: 'Bitte geben Sie Ihren Namen ein.',
            email: 'Keine gültige E-Mail Adresse.'
        }
    });*/
    
    
    // accordion
	$('#accordion .button-more').on('click', function() {
		$(this).next('#accordion-wrapper').slideToggle(500);
		$(this).parent('#accordion').toggleClass('open');
	});
});

